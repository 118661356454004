<template>
  <div
    class="container"
    v-title
    :data-title="$t('i18n.downloadTemplate')"
    id="downloadTemplate"
  >
    <div id="outer-title">{{ $t("i18n.downloadTemplate") }}</div>
    <el-form ref="form" :model="form" label-width="120px">
      <template v-for="item in data" :key="item.label">
        <el-form-item :label="$t('i18n.' + item.label) + '：'">
          <el-button type="primary">
            <a :href="item.url">{{ $t("i18n.downloadTemplate") }}</a>
          </el-button>
          <el-upload
            class="upload-demo"
            :limit="1"
            action=""
            :http-request="item.method"
          >
            <el-button size="small" type="reset">
              {{ $t("i18n.import") }}
            </el-button>
          </el-upload>
        </el-form-item>
      </template>
    </el-form>
  </div>
</template>

<script>
import { reactive, toRefs, getCurrentInstance } from "vue";
import { useI18n } from "vue-i18n";
export default {
  name: "DataIn",
  setup(props, { emit }) {
    emit("public_header", true);
    const { proxy } = getCurrentInstance();
    const { t } = useI18n();
    const state = reactive({
      data: [
        {
          label: "replacements",
          method: (file) => {
            importContract(file, 20);
          },
          url: proxy.$config.url.pjUpkeep,
        },
        {
          label: "repairTwo",
          method: (file) => {
            importContract(file, 30);
          },
          url: proxy.$config.url.xlUpkeep,
        },
        {
          label: "techConsultation",
          method: (file) => {
            importContract(file, 40);
          },
          url: proxy.$config.url.zxUpkeep,
        },
        {
          label: "maintenanceContract",
          method: (file) => {
            importContract(file, 10);
          },
          url: proxy.$config.url.wbUpkeep,
        },
      ],
    });

    const importContract = async (file, type) => {
      let param = new FormData();
      param.append("file", file.file);
      await proxy.$api.system.importContract(type, param);
      proxy.$defined.tip(t("i18n.import") + t("i18n.success"), "success");
    };

    return {
      ...toRefs(state),
      importContract,
    };
  },
};
</script>

<style lang="scss">
#downloadTemplate {
  .el-form {
    .el-form-item__label {
      padding-right: 20px;
      font-size: $font + 2;
    }
    padding: 30px 0 100px;
    .upload-demo {
      display: inline-block;
      margin: 0 30px 30px;
      span {
        font-size: $font;
      }
    }
    .el-upload-list {
      display: inline-block;
    }
  }
  a {
    color: inherit !important;
  }
}
</style>
